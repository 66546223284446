var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',{staticClass:"card"},[_c('div',{staticClass:"flex justify-end"},[_c('vs-button',{directives:[{name:"round",rawName:"v-round"}],staticClass:"newuser-btn mb-4",attrs:{"size":"medium"},on:{"click":function($event){return _vm.$router.push({
            name: 'user-create',
          })}}},[_c('plus-icon',{staticClass:"button-icon",attrs:{"size":"1.5x"}}),_vm._v("New user ")],1)],1),_c('vs-table',{attrs:{"data":_vm.users},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].fullName}},[_vm._v(" "+_vm._s(data[indextr].fullName)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].userType}},[_vm._v(" "+_vm._s(data[indextr].userType)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].email}},[_vm._v(" "+_vm._s(data[indextr].email)+" ")]),_c('vs-td',{attrs:{"data":tr.id}},[_c('a',{staticClass:"underline",on:{"click":function($event){return _vm.sendCredentials(tr._id)}}},[_vm._v(_vm._s(tr.credentialsSent ? "Resend credentials" : "Send credentials"))])]),_c('vs-td',{attrs:{"data":tr.id}},[_c('a',{staticClass:"underline",on:{"click":function($event){return _vm.$router.push({
                  name: 'user-create',
                  query: { id: tr._id },
                })}}},[_vm._v("Edit")])])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("Name ")]),_c('vs-th',[_vm._v("Role ")]),_c('vs-th',[_vm._v(" Email")]),_c('vs-th',[_vm._v("  ")]),_c('vs-th',[_vm._v("  ")])],1)],2),_c('div',[_c('vs-row',{staticClass:"px-5 my-10 flex justify-between items-center"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('span',[_vm._v("Records per page")]),_c('vs-select',{staticClass:"records-per-page",model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}},_vm._l((_vm.limitValue),function(item,index){return _c('vs-select-item',{key:index,staticClass:"w-1/12",attrs:{"text":item.text,"value":item.value}})}),1)],1),_c('v-pagination',{attrs:{"page-count":_vm.totalPage},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }